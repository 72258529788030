import { SmartStorage } from "smart-core-util";
import iJsBridge from "smart-javascript-bridge";
import * as tinyCookie from "tiny-cookie";
import { Toast } from 'mint-ui';
// import store from "../store/index";
import { miceService } from "../service/miceService";
import Vue from 'vue';

import pdfPopup from '@/components/PdfPopup/plugin.js'
const pdfPop = new pdfPopup({
  Vue
});

let publicFun = { 
  setResult(){
    this.switchContainer('setResult','')
  },
  async call(options){
    if(window.flutter_inappwebview){
      switch (options.method) {
        case "SMGeneral.openNewWebSiteForResult":
          this._flutter(options)
          break;
      }
      return
    }
    if(options.postData.loadUrl){
      this.switchContainer('call',options.postData.loadUrl, options);
    }else{
      this.switchContainer('call','', options);
    }
  },
  async openWebapp(options){
    if(window.flutter_inappwebview){
      this._flutter(options)
      return
    }
    if(options.loadUrl){
      this.switchContainer('openWebapp',options.loadUrl, options);
    }else{
      this.switchContainer('openWebapp','', options);
    }
  },
  async close(){
    const eventData = SmartStorage.get("eventData") || {};
    const ieventUrl = SmartStorage.get("Uris").Uris["webapp-ievent"];
    const sessionId = await this.saveCacheData();
    let ievent = `${ieventUrl}/eventDetail?miceId=${eventData.miceId}&sessionId=${sessionId}&rad=${sessionId}`;
    if(SmartStorage.get('search') || SmartStorage.get('isFromMore') || SmartStorage.get('recommend')){
      if(SmartStorage.get("tenant_code") == 'roche'){
        ievent = `${ieventUrl}/Home/More?&rad=${sessionId}&rnd=${new Date().getTime()}`
      }else{
        ievent = `${ieventUrl}/application?sessionId=${sessionId}&rnd=${new Date().getTime()}`
      }
      SmartStorage.remove('search')
      SmartStorage.remove('isFromMore')
      SmartStorage.remove('recommend')
      SmartStorage.remove('paramCode')
      SmartStorage.remove('authorize')
      SmartStorage.remove('goAlipay')
      SmartStorage.remove('goAlipay')
    }
    this.switchContainer('close',ievent)
  },
  
  // flutter
  async _flutter(options){
    if(!options.postData.loadUrl){
      return
    } 
    let params = {
      "url": options.postData.loadUrl,
      "cookies": tinyCookie.getAllCookies()
    }
    if(options.postData.browserMode){
      params.postData = {
        browserMode: {
          navBarVisibilty: true
        }
      }
    }
    console.log('===flutter跳转,SmGeneral.openNewWebSite');
    console.log('====打开参数',params);
    let result = await window.flutter_inappwebview.callHandler("SmGeneral.openNewWebSite", JSON.stringify(params));
    if (options.callback) {
      options.callback(result);
    }
  },
  // 浏览器模拟
  async _browser(hostUrl) {
    window.open(hostUrl);
  },
  // h5模式
  async _h5(hostUrl) {
    window.open(hostUrl, "_self");
  },
  switchContainer(Fun,url,options = {},isPDF){
    const container = SmartStorage.get("container");
    const IJsBridge =  new iJsBridge({alert: Toast});
    if(SmartStorage.get("isH5")){
      this._browser(url);
      return
    }
    console.log('跳转地址',url)
    if(SmartStorage.get("tenant_code") == 'novartis' && Fun == 'close'){
      IJsBridge[Fun](options)
      return
    }
    switch (container) {
      // case "miniprogram":
      //   window.wx.miniProgram.navigateTo({
      //     url: '/pages/eventDetail', //小程序必须有该目录
      //   })
      //   break;
      case "browser":
        if(!url){
          return
        }
        this._browser(url);
        break;
      case "h5":
        if(!url){
          return
        }
        if(url == 'mealCombineData'){
          IJsBridge[Fun](options)
          return
        }
        if(isPDF){ // PDF使用window.open
          this._browser(url);
        }
        this._h5(url);
        break;
      case "native":
      default:
        IJsBridge[Fun](options)
        break;
    }
  },
  // flutter 图片上传
  async uploadFile(uploadFileData,callback){
    // 获取权限
    let power = await window.flutter_inappwebview.callHandler("Permission.checkAndRequestCamera",'');
    if(!power) return
    let params = {
        "uploadUrl": process.env.VUE_APP_GATEWAY + '/foundation/aliyun/api/oss/upload', //图片上传的oss地址
        "hideCamera": uploadFileData.hideCamera || false, // 隐藏拍照
        "hideGallery": uploadFileData.hideGallery || false, // 隐藏本地文件
        "hidePdfAndOfd": uploadFileData.hidePdfAndOfd || false, // 隐藏ofd和pdf上传选项
    }
    let graph = await window.flutter_inappwebview.callHandler("App.openImagePicker",JSON.stringify(params));
    callback(graph)
  },
  // 打开pdf
  openPdfImg(pdfUrl) {
    // if(window.flutter_inappwebview){
    //   window.flutter_inappwebview.callHandler("App.openPdf", pdfUrl, true);
    //   return
    // }
    // if(window.__wxjs_environment === "miniprogram"){
      pdfPop.render({
        pdfUrl: encodeURI(pdfUrl)
      })
      return
    // }
    // const options = {
    //   method: "SMGeneral.openNewWebSite",
    //   postData: {
    //       loadUrl: pdfUrl,
    //       browserMode: {
    //           navBarVisibilty: true,
    //           titleTextColor: "#ffffff",
    //           navBackgroundColor: SmartStorage.get('--themeColor')
    //       }
    //   },
    //   callback: (viewData) => {
         
    //   }
    // };
    // this.switchContainer('call',pdfUrl,options,true)
  },
  // 业务数据存入mangodb
  async saveCacheData() {
    const cacheData = {
      supplierId: SmartStorage.get("supplierId"),
      userId: SmartStorage.get("userId"),
      role_codes: SmartStorage.get("role_codes"),
      directory_id: SmartStorage.get("directory_id"),
      tenant_code: SmartStorage.get("tenant_code"),
      token: SmartStorage.get("token"),
      container: SmartStorage.get("container"),
      tenant_id: SmartStorage.get("tenant_id"),
      platform: SmartStorage.get("platform"),
    };
    const params = {
      Data: cacheData,
      CacheEntryOptions: {
        AbsoluteExpirationRelativeToNow: "08:00:00", //从现在开始计时(时:分:秒）过期
      },
    };
    const res = await miceService.saveCacheData(params);
    return res.content || "";
  },
};
export default publicFun;
